{
  "name": "abogados-frontend",
  "version": "1.98.1",
  "engines": {
    "node": "=20.11.0"
  },
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "test": "ng test",
    "lint": "ng lint",
    "e2e": "playwright test",
    "e2e:ui": "playwright test --ui",
    "prepare": "husky install",
    "build:dev": "ng build --configuration=development",
    "build:staging": "ng build --configuration=staging",
    "build:demo": "ng build --configuration=demo",
    "build:prod": "ng build --configuration=production"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.4",
    "@angular/cdk": "^17.3.4",
    "@angular/common": "^17.3.4",
    "@angular/compiler": "^17.3.4",
    "@angular/core": "^17.3.4",
    "@angular/forms": "^17.3.4",
    "@angular/material": "^17.3.4",
    "@angular/platform-browser": "^17.3.4",
    "@angular/platform-browser-dynamic": "^17.3.4",
    "@angular/router": "^17.3.4",
    "@fortawesome/fontawesome-free": "^5.9.0",
    "@sentry/angular-ivy": "^7.60.0",
    "bootstrap": "^4.3.1",
    "ngx-google-analytics": "^14.0.1",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.4",
    "@angular-eslint/builder": "17.2.0",
    "@angular-eslint/eslint-plugin": "17.2.0",
    "@angular-eslint/eslint-plugin-template": "17.2.0",
    "@angular-eslint/schematics": "17.2.0",
    "@angular-eslint/template-parser": "17.2.0",
    "@angular/cli": "^17.3.4",
    "@angular/compiler-cli": "^17.3.4",
    "@commitlint/cli": "^17.6.7",
    "@commitlint/config-conventional": "^17.6.7",
    "@playwright/test": "^1.37.1",
    "@semantic-release/changelog": "^6.0.3",
    "@semantic-release/git": "^10.0.1",
    "@types/jasmine": "~4.3.0",
    "@typescript-eslint/eslint-plugin": "6.18.0",
    "@typescript-eslint/parser": "6.18.0",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "^8.8.0",
    "eslint-plugin-prettier": "^5.0.0",
    "husky": "^8.0.3",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "^3.0.0",
    "prettier-eslint": "^15.0.1",
    "semantic-release": "^23.1.1",
    "typescript": "~5.2.2"
  }
}
